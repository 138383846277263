import Head from 'next/head';
import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { HeaderRegistration } from '../../components/HeaderRegistration';
import { useDisplayMode } from '../../utils/useDisplayMode';
import { ILayout } from '@brainysoft/lk-components';

export const Layout: React.FC<ILayout> = (props) => {
  useDisplayMode();

  const layoutStyles = useThemeStyles('Layout');

  return (
    <React.Fragment>
      <Head>
        <title>{props.title}</title>
        <meta charSet='utf-8' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      </Head>
      <HeaderRegistration />
      <div className={`content-wrap ${layoutStyles.registrationPageWrap}`}>{props.children}</div>
    </React.Fragment>
  );
};
